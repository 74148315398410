import {
  FormEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { EditDialogRef } from '../EditDialog/EditDialog'
import { baseApiUrl } from '@hooks/useApi'

import './ExportDialog.css'

interface ExportDialogProps {
  closeModal: () => void
}

const ExportDialog = forwardRef<EditDialogRef, ExportDialogProps>(
  ({ closeModal }, ref) => {
    const intl = useIntl()
    const dialogRef = useRef<HTMLDialogElement>(null)

    const today = new Date().toISOString().split('T')[0]

    const lastMonthDate = new Date()
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1)

    const [startDate, setStartDate] = useState<string>(
      lastMonthDate.toISOString().split('T')[0]
    )
    const [endDate, setEndDate] = useState<string>(today)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    useImperativeHandle(ref, () => ({
      showModal: () => dialogRef.current.showModal(),
      closeModal: () => dialogRef.current.close()
    }))

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setLoading(true)
      setError('')
      try {
        const start = new Date(startDate)
        const end = new Date(endDate)

        if (start.getTime() > end.getTime()) {
          setError(
            intl.formatMessage({
              id: 'edit.export.error-dates'
            })
          )
          return
        }

        const params = new URLSearchParams({
          startDate: start.getTime().toString(),
          endDate: end.getTime().toString()
        })

        const base = `${baseApiUrl}/panel/export`

        const response = await fetch(`${base}?${params}`)
        const blob = await response.blob()
        const element = document.createElement('a')
        element.href = URL.createObjectURL(blob)
        element.download = `KPIS_${start.getUTCDay()}-${start.getUTCMonth()}-${start.getUTCFullYear()}_${end.getUTCDay()}-${end.getUTCMonth()}-${end.getUTCFullYear()}`
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
        closeModal()
      } finally {
        setLoading(false)
      }
    }

    const handleClose = () => {
      setLoading(false)
      setError('')
      closeModal()
    }

    return (
      <dialog id="kpi-export-dialog" ref={dialogRef}>
        <form onSubmit={handleSubmit}>
          <h2>
            <FormattedMessage id="edit.export.title" />
          </h2>

          <div className="dates">
            <div>
              <label htmlFor="startDate">
                <FormattedMessage id="edit.export.startDate" />
              </label>
              <input
                id="startDate"
                name="startDate"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="endDate">
                <FormattedMessage id="edit.export.endDate" />
              </label>
              <input
                id="startDate"
                name="endDate"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </div>
          </div>
          {error ? <span className="error">{error}</span> : null}
          <div className="buttons">
            <button type="button" className="cancel" onClick={handleClose}>
              <FormattedMessage id="edit.remove.cancel" />
            </button>
            <button type="submit" disabled={loading}>
              <FormattedMessage id="edit.export.button" />
            </button>
          </div>
        </form>
      </dialog>
    )
  }
)

ExportDialog.displayName = 'ExportDialog'

export default ExportDialog
