import Check from '../Pieces/Check'

interface NegativeRadioProps {
  checked: boolean
  handleChange: () => void
}

export default function NegativeRadio({
  checked,
  handleChange
}: NegativeRadioProps) {
  return (
    <Check
      checked={checked}
      handleChange={handleChange}
      label="edit.table.head.negative"
    />
  )
}
