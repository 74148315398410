export class ParserError extends Error {
  index: number
  token: string
  type: 'symbol' | 'kpi'

  constructor(index: number, token: string, type: 'symbol' | 'kpi') {
    super(`Error at index ${index}: Unexpected token "${token}"`)
    this.index = index
    this.token = token
    this.type = type
    this.name = 'ParserError'
  }
}
