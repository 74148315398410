import { FormattedMessage } from '@components/Intl/FormattedMessage'
import { Dispatch, SetStateAction } from 'react'
import { KpiMetadata } from 'src/server/types'
import { Frequency } from 'src/types'
import Radio from '../Pieces/Radio'

interface FrequencyRadioProps {
  setEditedKpi: Dispatch<SetStateAction<Omit<KpiMetadata, 'id'>>>
  selectedValue: string
}

export default function FrequencyRadio({
  setEditedKpi,
  selectedValue
}: FrequencyRadioProps) {
  return (
    <>
      <label>
        <FormattedMessage id="edit.table.head.frequency" />
      </label>
      <div className="frequency">
        <div>
          <Radio
            label="edit.dialog.edit.frequency.weekly"
            name="frequency"
            selectedValue={selectedValue}
            value="weekly"
            handleChange={(value) =>
              setEditedKpi((prev) => ({
                ...prev,
                kpiFrequency: value as Frequency
              }))
            }
          />
        </div>
        <div>
          <Radio
            label="edit.dialog.edit.frequency.monthly"
            name="frequency"
            selectedValue={selectedValue}
            value="monthly"
            handleChange={(value) =>
              setEditedKpi((prev) => ({
                ...prev,
                kpiFrequency: value as Frequency
              }))
            }
          />
        </div>
      </div>
    </>
  )
}
