import {
  FormattedMessage,
  IntlMessageKeys
} from '@components/Intl/FormattedMessage'
import { ChangeEvent } from 'react'

interface FormulaProps {
  formula: string
  title: IntlMessageKeys
  name: string
  handleChange: (value: string) => void
}

export const parseFormulaToTitles = (
  formula: string,
  titleByIdRecord: Record<number, string>
) => {
  const regex = /(sum|avg)\((\d+)\)|\{(\d+)\}/g

  return formula.replace(regex, (_, func, num1, num2) => {
    const id = num1 || num2
    const replacement = titleByIdRecord[Number(id)] || id

    return func ? `${func}({${replacement}})` : `{${replacement}}`
  })
}

export default function Formula({
  formula,
  title,
  name,
  handleChange: handleChangeValue
}: FormulaProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeValue(e.target.value)
  }

  return (
    <>
      <label htmlFor={title}>
        <FormattedMessage id={title} />
      </label>
      <input type="text" name={name} value={formula} onChange={handleChange} />
    </>
  )
}
