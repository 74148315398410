import { FormattedMessage } from '@components/Intl/FormattedMessage'
import { Link } from 'react-router-dom'
import SelectLanguage from './SelectLanguage/SelectLanguage'
import { Icons } from '@static/icons'
import { useAppDispatch, useAppSelector } from 'universal-scripts'
import { logout } from 'src/store/reducers/user.client'

import './Menu.css'

interface MenuProps {
  open: boolean
}

export default function Menu({ open }: MenuProps) {
  const dispatch = useAppDispatch()
  const user = useAppSelector((s) => s.user)

  const handleLogout = () => {
    dispatch(logout())
    document.cookie =
      'session' + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }

  return (
    <div id="menu" className={open ? 'open' : 'closed'}>
      <menu>
        <li>
          <Link to="/">
            <FormattedMessage id="dashboard.title" />
          </Link>
        </li>
        <li>
          <Link to="/overview">
            <FormattedMessage id="overview.title" />
          </Link>
        </li>
        {user.isAdmin ? (
          <li>
            <Link to="/admin/kpis">Admin</Link>
          </li>
        ) : null}
        <li>
          <SelectLanguage />
        </li>
      </menu>
      <button onClick={handleLogout}>
        <FormattedMessage id="menu.logout" />
        <img src={Icons.logoutIcon.source} alt={Icons.logoutIcon.alt} />
      </button>
    </div>
  )
}
