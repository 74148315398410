import {
  FormEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { categoriesData } from 'src/data/categories'
import { KpiMetadata } from 'src/server/types'
import { UnitsIcons } from '@static/icons/units'
import TargetFormulaLayout from './Layouts/TargetFormulaLayout'
import ValueFormulaLayout from './Layouts/ValueFormulaLayout'
import CommonLayout from './Layouts/CommonLayout'
import { parseFormula, reconstructExpression } from '@utils/parser'
import { ParserError } from '@utils/errors'
import { parseFormulaToTitles } from './Pieces/Formula'

import './EditDialog.css'

interface EditDialogProps {
  kpi: KpiMetadata
  titleByIdRecord: Record<number, string>
  handleSubmit: (newKpi: KpiMetadata) => void
  closeModal: () => void
}

export interface EditDialogRef {
  showModal: () => void
  closeModal: () => void
}

const EditDialog = forwardRef<EditDialogRef, EditDialogProps>(
  ({ kpi, titleByIdRecord, handleSubmit, closeModal }, ref) => {
    const dialogRef = useRef<HTMLDialogElement>(null)
    const [editedKpi, setEditedKpi] = useState<Omit<KpiMetadata, 'id'>>(kpi)
    const [error, setError] = useState<ParserError>(null)

    const categories = categoriesData.map((category) => category.name)
    const subcategories = categoriesData
      .map((category) => category.subcategories)
      .flat()
    const intl = useIntl()
    const categoriesSelect = useMemo(
      () =>
        categories.map((category) => ({
          label: intl.formatMessage({ id: `overview.filter.${category}.name` }),
          value: category
        })),
      [categories, intl]
    )
    const subcategoriesSelect = useMemo(
      () =>
        subcategories.map((subcategory) => ({
          label: intl.formatMessage({
            id: `overview.filter.production.subcategories.${subcategory}`
          }),
          value: subcategory
        })),
      [subcategories, intl]
    )
    const units = useMemo(() => {
      const labels = Object.keys(UnitsIcons)
      return labels.map((name) => ({
        label: intl.formatMessage({ id: `edit.units.${name}` }),
        value: name
      }))
    }, [intl])

    useImperativeHandle(ref, () => ({
      showModal: () => dialogRef.current.showModal(),
      closeModal: () => dialogRef.current.close()
    }))

    useEffect(() => {
      const copyKpi = structuredClone(kpi)
      if (copyKpi.formula) {
        copyKpi.formula = parseFormulaToTitles(copyKpi.formula, titleByIdRecord)
      }
      if (copyKpi.targetFormula) {
        copyKpi.targetFormula = parseFormulaToTitles(
          copyKpi.targetFormula,
          titleByIdRecord
        )
      }

      setEditedKpi(copyKpi)
    }, [titleByIdRecord, kpi])

    const handleTopSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (hasFormula || hasTargetFormula) {
        try {
          const idByTitleRecord: Record<string, number> = Object.fromEntries(
            Object.entries(titleByIdRecord).map(([id, title]) => [
              title,
              Number(id)
            ])
          )
          const astThree = parseFormula(
            hasFormula ? editedKpi.formula : editedKpi.targetFormula,
            idByTitleRecord
          )
          const reconstructedExpression = reconstructExpression(
            astThree,
            idByTitleRecord
          )
          setError(null)
          return handleSubmit(
            hasFormula
              ? { ...editedKpi, formula: reconstructedExpression }
              : { ...editedKpi, targetFormula: reconstructedExpression }
          )
        } catch (err) {
          if (err instanceof ParserError) {
            return setError(err)
          }
        }
      }
      setError(null)
      handleSubmit(editedKpi)
    }

    const hasTargetFormula = !!editedKpi.targetFormula
    const hasFormula = !!editedKpi.formula

    return (
      <dialog id="kpi-edit-dialog" ref={dialogRef}>
        <form onSubmit={handleTopSubmit}>
          <header>
            <button type="submit">
              <FormattedMessage id="edit.dialog.edit.header.apply" />
            </button>
            <div
              role="button"
              onClick={() => {
                setError(null)
                closeModal()
              }}
              aria-label="Close Filters"
            >
              <button className="close"></button>
            </div>
          </header>
          {error ? (
            <span className="error">
              {error.type === 'kpi'
                ? intl.formatMessage(
                    {
                      id: 'edit.error.formula-kpi'
                    },
                    {
                      kpi: error.token
                    }
                  )
                : intl.formatMessage(
                    {
                      id: 'edit.error.formula-syntax'
                    },
                    {
                      token: error.token
                    }
                  )}
            </span>
          ) : null}
          {hasTargetFormula ? (
            <TargetFormulaLayout
              categoriesSelect={categoriesSelect}
              editedKpi={editedKpi}
              setEditedKpi={setEditedKpi}
              subcategoriesSelect={subcategoriesSelect}
              units={units}
            />
          ) : hasFormula ? (
            <ValueFormulaLayout
              categoriesSelect={categoriesSelect}
              editedKpi={editedKpi}
              setEditedKpi={setEditedKpi}
              subcategoriesSelect={subcategoriesSelect}
              units={units}
            />
          ) : (
            <CommonLayout
              categoriesSelect={categoriesSelect}
              editedKpi={editedKpi}
              setEditedKpi={setEditedKpi}
              subcategoriesSelect={subcategoriesSelect}
              units={units}
            />
          )}
        </form>
      </dialog>
    )
  }
)

EditDialog.displayName = 'EditDialog'

export default EditDialog
