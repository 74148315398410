import { Dispatch, SetStateAction } from 'react'
import { KpiMetadata, Units } from 'src/server/types'
import Formula from '../Pieces/Formula'
import WarehouseRadio from '../components/WarehouseRadio'
import TitleInput from '../components/TitleInput'
import NaturalRadio from '../components/NaturalRadio'
import CategorySelect from '../components/CategorySelect'
import NegativeRadio from '../components/NegativeRadio'
import SubcategorySelect from '../components/SubcategorySelect'
import FrequencyRadio from '../components/FrequencyRadio'
import UnitSelect from '../components/UnitSelect'
import { SelectOption } from '../Pieces/Select'
import { FormattedMessage } from '@components/Intl/FormattedMessage'

export interface LayoutProps {
  editedKpi: KpiMetadata

  setEditedKpi: Dispatch<SetStateAction<Omit<KpiMetadata, 'id'>>>
  categoriesSelect: SelectOption[]
  subcategoriesSelect: SelectOption[]
  units: SelectOption[]
}

export default function TargetFormulaLayout({
  editedKpi,
  categoriesSelect,
  subcategoriesSelect,
  units,
  setEditedKpi
}: LayoutProps) {
  return (
    <section>
      <div>
        <TitleInput
          handleChange={(value) =>
            setEditedKpi((prev) => ({ ...prev, title: value }))
          }
          value={editedKpi.title ?? ''}
        />
      </div>
      <div>
        <WarehouseRadio
          setEditedKpi={setEditedKpi}
          selectedValue={editedKpi.warehouse?.toLowerCase()}
        />
      </div>
      <div>
        <Formula
          handleChange={(value) =>
            setEditedKpi((prev) => ({ ...prev, targetFormula: value }))
          }
          title="edit.dialog.target-formula"
          formula={editedKpi.targetFormula}
          name="target-formula"
        />
      </div>
      <div>
        <FrequencyRadio
          setEditedKpi={setEditedKpi}
          selectedValue={editedKpi.kpiFrequency}
        />
      </div>
      <div>
        <CategorySelect
          categoriesSelect={categoriesSelect}
          handleChange={(value) =>
            setEditedKpi((prev) => ({ ...prev, category: value }))
          }
          value={editedKpi.category}
        />
      </div>
      <div className="checkbox-container">
        <label>
          <FormattedMessage id="edit.dialog.params" />
        </label>
        <div>
          <div>
            <NaturalRadio
              checked={editedKpi.isNatural ?? false}
              handleChange={() =>
                setEditedKpi((prev) => ({
                  ...prev,
                  isNatural: !prev.isNatural
                }))
              }
            />
          </div>
          <div>
            <NegativeRadio
              checked={editedKpi.isNegative ?? false}
              handleChange={() =>
                setEditedKpi((prev) => ({
                  ...prev,
                  isNegative: !prev.isNegative
                }))
              }
            />
          </div>
        </div>
      </div>
      <div>
        <SubcategorySelect
          category={editedKpi.category}
          handleChange={(value) =>
            setEditedKpi((prev) => ({
              ...prev,
              subcategory: value
            }))
          }
          subcategoriesSelect={subcategoriesSelect}
          value={editedKpi.subcategory}
        />
      </div>
      <div></div>
      <div>
        <UnitSelect
          handleChange={(value) =>
            setEditedKpi((prev) => ({ ...prev, unit: value as Units }))
          }
          units={units}
          value={editedKpi.unit}
        />
      </div>
    </section>
  )
}
