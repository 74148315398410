/**
0 S → Suma
1 Suma → Suma+Producto
2 Suma → Suma-Producto
3 Suma → Producto
4 Producto → Producto*Valor
5 Producto → Producto/Valor
6 Producto → Valor
7 Valor → (Suma)
8 Valor → n
9 Valor → {kpi}
10 Valor → func({kpi})
 */

import { ParserError } from './errors'

const input =
  'avg({texto1 continuacion1 continuacion2}) + sum({texto2}) * {texto3} - {texto4} / 22'

const transitionTable = [
  //[Suma, Producto, Valor]
  [1, 2, 3],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [12, 13, 14],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, 20, 3],
  [null, 21, 3],
  [null, null, 22],
  [null, null, 23],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [29, 13, 14],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, 32, 14],
  [null, 33, 14],
  [null, null, null],
  [null, null, 34],
  [null, null, 35],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null],
  [null, null, null]
]

const actionsTable = [
  // [+, -, *, /, (, ), n, kpi, func, $]
  [null, null, null, null, 's4', null, 's5', 's6', 's7', null],
  ['s8', 's9', null, null, null, null, null, null, null, 'acc'],
  ['r3', 'r3', 's10', 's11', null, null, null, null, null, 'r3'],
  ['r6', 'r6', 'r6', 'r6', null, null, null, null, null, 'r6'],
  [null, null, null, null, 's15', null, 's16', 's17', 's18', null],
  ['r8', 'r8', 'r8', 'r8', null, null, null, null, null, 'r8'],
  ['r9', 'r9', 'r9', 'r9', null, null, null, null, null, 'r9'],
  [null, null, null, null, 's19', null, null, null, null, null],
  [null, null, null, null, 's4', null, 's5', 's6', 's7', null],
  [null, null, null, null, 's4', null, 's5', 's6', 's7', null],
  [null, null, null, null, 's4', null, 's5', 's6', 's7', null],
  [null, null, null, null, 's4', null, 's5', 's6', 's7', null],
  ['s24', 's25', null, null, null, 's26', null, null, null, null],
  ['r3', 'r3', 's27', 's28', null, 'r3', null, null, null, null],
  ['r6', 'r6', 'r6', 'r6', null, 'r6', null, null, null, null],
  [null, null, null, null, 's15', null, 's16', 's17', 's18', null],
  ['r8', 'r8', 'r8', 'r8', null, 'r8', null, null, null, null],
  ['r9', 'r9', 'r9', 'r9', null, 'r9', null, null, null, null],
  [null, null, null, null, 's30', null, null, null, null, null],
  [null, null, null, null, null, null, null, 's31', null, null],
  ['r1', 'r1', 's10', 's11', null, null, null, null, null, 'r1'],
  ['r2', 'r2', 's10', 's11', null, null, null, null, null, 'r2'],
  ['r4', 'r4', 'r4', 'r4', null, null, null, null, null, 'r4'],
  ['r5', 'r5', 'r5', 'r5', null, null, null, null, null, 'r5'],
  [null, null, null, null, 's15', null, 's16', 's17', 's18', null],
  [null, null, null, null, 's15', null, 's16', 's17', 's18', null],
  ['r7', 'r7', 'r7', 'r7', null, null, null, null, null, 'r7'],
  [null, null, null, null, 's15', null, 's16', 's17', 's18', null],
  [null, null, null, null, 's15', null, 's16', 's17', 's18', null],
  ['s24', 's25', null, null, null, 's36', null, null, null, null],
  [null, null, null, null, null, null, null, 's37', null, null],
  [null, null, null, null, null, 's38', null, null, null, null],
  ['r1', 'r1', 's27', 's28', null, 'r1', null, null, null, null],
  ['r2', 'r2', 's27', 's28', null, 'r2', null, null, null, null],
  ['r4', 'r4', 'r4', 'r4', null, 'r4', null, null, null, null],
  ['r5', 'r5', 'r5', 'r5', null, 'r5', null, null, null, null],
  ['r7', 'r7', 'r7', 'r7', null, 'r7', null, null, null, null],
  [null, null, null, null, null, 's39', null, null, null, null],
  ['r10', 'r10', 'r10', 'r10', null, null, null, null, null, 'r10'],
  ['r10', 'r10', 'r10', 'r10', null, 'r10', null, null, null, null]
]
type ASTNode = {
  type: string
  value?: string
  children?: ASTNode[]
}

type StackItem = {
  state: number
  node?: ASTNode
}

export function lexer(input: string): string[] {
  const tokenRegex =
    /\s*(\b[a-zA-Z]+\b|\+|-|\*|\/|\(|\)|\{[^{}]+\}|\d+\.?\d*)\s*/g

  const tokens: string[] = []
  let match

  while ((match = tokenRegex.exec(input)) !== null) {
    tokens.push(match[1])
  }

  return tokens
}

function getTokenIndex(token: string) {
  const tokens = ['+', '-', '*', '/', '(', ')']
  const index = tokens.indexOf(token)
  if (index >= 0) return index
  if (!Number.isNaN(Number(token))) {
    return 6
  } else if (token.includes('{') && token.includes('}')) {
    return 7
  } else if (token === 'avg' || token === 'sum') {
    return 8
  } else if (token === '$') {
    return 9
  }
  return -1
}

function getNonTerminalIndex(symbol: string): number {
  const nonTerminals = ['Suma', 'Producto', 'Valor']
  return nonTerminals.indexOf(symbol)
}

export function parseFormula(
  formula: string,
  idByTitle: Record<string, number>
) {
  let pointer = 0
  let currentIndex = 0

  const stack: StackItem[] = [{ state: 0 }]
  const tokens = [...lexer(formula), '$']

  while (true) {
    const currentState = stack[stack.length - 1].state
    const currentToken = tokens[pointer]
    currentIndex += currentToken.length
    const action = actionsTable[currentState][getTokenIndex(currentToken)]

    if (!action) {
      throw new ParserError(
        currentIndex - currentToken.length,
        currentToken,
        'symbol'
      )
    }

    const actionStep = Number(action.substring(1))

    if (action.startsWith('s')) {
      if (currentToken.startsWith('{') && currentToken.endsWith('}')) {
        const name = currentToken.slice(1, -1)
        if (!idByTitle[name]) {
          throw new ParserError(currentIndex - currentToken.length, name, 'kpi')
        }
      }
      stack.push({
        state: actionStep,
        node: { type: 'terminal', value: currentToken }
      })
      pointer++
    } else if (action.startsWith('r')) {
      const rules: { lhs: string; rhs: number }[] = [
        { lhs: 'S', rhs: 1 },
        { lhs: 'Suma', rhs: 3 },
        { lhs: 'Suma', rhs: 3 },
        { lhs: 'Suma', rhs: 1 },
        { lhs: 'Producto', rhs: 3 },
        { lhs: 'Producto', rhs: 3 },
        { lhs: 'Producto', rhs: 1 },
        { lhs: 'Valor', rhs: 3 },
        { lhs: 'Valor', rhs: 1 },
        { lhs: 'Valor', rhs: 1 },
        { lhs: 'Valor', rhs: 4 }
      ]

      const rule = rules[actionStep]
      const children: ASTNode[] = []
      for (let i = 0; i < rule.rhs; i++) {
        const popped = stack.pop()
        if (popped?.node) children.unshift(popped.node)
      }

      const newNode: ASTNode = { type: rule.lhs, children }
      const prevState = stack[stack.length - 1].state
      const gotoState =
        transitionTable[prevState][getNonTerminalIndex(rule.lhs)]

      if (gotoState !== null) {
        stack.push({ state: gotoState, node: newNode })
      } else {
        throw new ParserError(
          currentIndex - currentToken.length,
          currentToken,
          'symbol'
        )
      }
    } else if (action === 'acc') {
      return stack[1].node || null
    }
  }
}

export function reconstructExpression(
  node: ASTNode,
  idByTitle: Record<string, number>
): string {
  if (!node) return ''

  if (node.type === 'terminal' && node.value) {
    if (node.value.startsWith('{') && node.value.endsWith('}')) {
      const name = node.value.slice(1, -1)
      if (idByTitle[name]) {
        return `{${idByTitle[name]}}`
      }
    }
    return node.value
  }

  if (node.children && node.children.length > 0) {
    return node.children
      .map((child) => reconstructExpression(child, idByTitle))
      .join(' ')
  }

  return ''
}
