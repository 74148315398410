import { FormattedMessage } from '@components/Intl/FormattedMessage'
import Select, { SelectOption } from '../Pieces/Select'

interface SubcategorySelectProps {
  category: string
  subcategoriesSelect: SelectOption[]
  value: string
  handleChange: (value) => void
}

export default function SubcategorySelect({
  category,
  handleChange,
  subcategoriesSelect,
  value
}: SubcategorySelectProps) {
  return (
    <>
      <label htmlFor="subcategory">
        <FormattedMessage id="edit.table.head.subcategory" />
      </label>
      {category !== 'production' ? (
        <div className="disabled-select" />
      ) : (
        <Select
          component="subcategory"
          handleChange={(value) => handleChange(value)}
          name="subcategory"
          options={subcategoriesSelect}
          value={value}
        />
      )}
    </>
  )
}
