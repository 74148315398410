import { FormattedMessage } from '@components/Intl/FormattedMessage'
import Select, { SelectOption } from '../Pieces/Select'

interface UnitSelectProps {
  units: SelectOption[]
  value: string
  handleChange: (value) => void
}

export default function UnitSelect({
  handleChange,
  units,
  value
}: UnitSelectProps) {
  return (
    <>
      <label htmlFor="units">
        <FormattedMessage id="edit.table.head.unit" />
      </label>
      <Select
        component="unit"
        handleChange={(value) => handleChange(value)}
        name="units"
        options={units}
        value={value}
      />
    </>
  )
}
