import {
  FormattedMessage,
  IntlMessageKeys
} from '@components/Intl/FormattedMessage'

interface RadioProps {
  value: string
  name: string
  label: IntlMessageKeys
  selectedValue: string
  handleChange: (value: string) => void
}

export default function Radio({
  label,
  name,
  value,
  selectedValue,
  handleChange
}: RadioProps) {
  return (
    <>
      <input
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={selectedValue === value}
        onChange={({ target: { value } }) => handleChange(value)}
        required
      />
      <label htmlFor={value}>
        <FormattedMessage id={label} />
      </label>
    </>
  )
}
