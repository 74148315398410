import { Dispatch, SetStateAction } from 'react'
import { KpiMetadata } from 'src/server/types'
import { FormattedMessage } from '@components/Intl/FormattedMessage'
import Radio from '../Pieces/Radio'

interface WarehouseRadioProps {
  setEditedKpi: Dispatch<SetStateAction<Omit<KpiMetadata, 'id'>>>
  selectedValue: string
}

export default function WarehouseRadio({
  setEditedKpi,
  selectedValue
}: WarehouseRadioProps) {
  return (
    <>
      <label>
        <FormattedMessage id="edit.table.head.warehouse" />
      </label>
      <div className="warehouse">
        <div>
          <Radio
            label="header.flag.fundilusa"
            name="warehouse"
            value="fundilusa"
            selectedValue={selectedValue}
            handleChange={(value) =>
              setEditedKpi((prev) => ({
                ...prev,
                warehouse: value.toUpperCase()
              }))
            }
          />
        </div>
        <div>
          <Radio
            label="header.flag.fundimaroc"
            name="warehouse"
            value="fundimaroc"
            selectedValue={selectedValue}
            handleChange={(value) =>
              setEditedKpi((prev) => ({
                ...prev,
                warehouse: value.toUpperCase()
              }))
            }
          />
        </div>
        <div>
          <Radio
            label="edit.dialog.warehouse.common"
            name="warehouse"
            value="common"
            selectedValue={selectedValue}
            handleChange={(value) =>
              setEditedKpi((prev) => ({
                ...prev,
                warehouse: value.toUpperCase()
              }))
            }
          />
        </div>
      </div>
    </>
  )
}
