import { useKpisPanelData } from '@hooks/useApi'
import { Icons } from '@static/icons'
import { UnitsIcons } from '@static/icons/units/index'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { KpiMetadata } from 'src/server/types'

interface EditTableProps {
  showDialog: (
    kpi: KpiMetadata,
    titleByIdRecord: Record<number, string>
  ) => void
  removeDialog: (kpi: KpiMetadata) => void
}

export default function EditTable({
  showDialog,
  removeDialog
}: EditTableProps) {
  const kpis = useKpisPanelData()
  const titleByIdRecord = useMemo(
    () => Object.fromEntries(kpis.map((kpi) => [kpi.id, kpi.title])),
    [kpis]
  )
  const intl = useIntl()

  return (
    <tbody>
      {kpis.map((kpi) => (
        <tr key={kpi.id.toString()}>
          <td aria-label={kpi.title}>{kpi.title}</td>
          <td>
            <FormattedMessage id={`overview.filter.${kpi.category}.name`} />
          </td>
          <td className="target">
            {Boolean(kpi.targetFormula) !== false ? (
              <img
                title={intl.formatMessage({
                  id: 'admin.kpi.target-calculated'
                })}
                src={Icons.calcIcon.source}
                alt={Icons.calcIcon.alt}
                role="button"
                aria-label={intl.formatMessage({
                  id: 'admin.kpi.target-calculated'
                })}
              />
            ) : (
              (kpi.target ?? '-')
            )}
          </td>
          <td className="target">
            {kpi.formula ? (
              <img
                title={intl.formatMessage({
                  id: 'admin.kpi.value-calculated'
                })}
                src={Icons.calcIcon.source}
                alt={Icons.calcIcon.alt}
                role="button"
                aria-label={intl.formatMessage({
                  id: 'admin.kpi.value-calculated'
                })}
              />
            ) : (
              <img
                title={intl.formatMessage({
                  id: 'admin.kpi.value-not-calculated'
                })}
                src={Icons.noCalcIcon.source}
                alt={Icons.noCalcIcon.alt}
                role="button"
                aria-label={intl.formatMessage({
                  id: 'admin.kpi.value-not-calculated'
                })}
              />
            )}
          </td>
          <td className="units">
            <img
              src={UnitsIcons[kpi.unit]}
              alt={'icon'}
              title={intl.formatMessage({ id: `edit.units.${kpi.unit}` })}
            />
          </td>
          <td className="buttons">
            <img
              title="Editar"
              src={Icons.editIcon.source}
              alt={Icons.editIcon.alt}
              role="button"
              aria-label="Editar KPI"
              onClick={() => showDialog(kpi, titleByIdRecord)}
            />
            <img
              title="Eliminar"
              src={Icons.trashIcon.source}
              alt={Icons.trashIcon.alt}
              role="button"
              aria-label="Eliminar KPI"
              onClick={() => removeDialog(kpi)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  )
}
