import {
  useFetch,
  useFetchCb,
  useFetchUncachedCb
} from '@gluedigital/ruse-fetch-extras'

import {
  type Overview,
  type Kpi,
  type Pending,
  type KpiMetadata,
  RoleMetadataDto,
  Roles,
  UpdateRoleMetadata,
  UserAccountDto
} from 'src/server/types'

export const baseApiUrl = process.env.API_URL || 'http://localhost:3000/api'

export function useKpisWithMetadata(): Overview {
  const kpis = useFetch<Overview>(`${baseApiUrl}/kpi/overview`, {
    credentials: 'include'
  })

  return kpis
}

export function useKpisActivity(center: number): Overview {
  const kpis = useFetch<Overview>(`${baseApiUrl}/kpi/activity`, {
    credentials: 'include',
    params: {
      center
    }
  })

  return kpis
}

export function useRefreshKpisActivity(): (
  center: number
) => Promise<Overview> {
  const refresh = useFetchCb<Overview>()

  return (center: number) =>
    refresh(`${baseApiUrl}/kpi/activity`, {
      credentials: 'include',
      params: { center }
    })
}

export function useKpisWithPendingData(): Pending {
  const pendingKpis = useFetch<Pending>(`${baseApiUrl}/kpi/pending`, {
    credentials: 'include'
  })

  return pendingKpis
}

export function useRefreshPendingKpis(): () => Promise<Pending> {
  const refresh = useFetchCb<Pending>()

  return () =>
    refresh(`${baseApiUrl}/kpi/pending`, {
      credentials: 'include'
    })
}

export function useUpdateKpis(): (body: Omit<Kpi, 'id'>[]) => Promise<Kpi[]> {
  const fetch = useFetchUncachedCb()

  return (body) =>
    fetch(`${baseApiUrl}/kpi`, {
      method: 'POST',
      body,
      credentials: 'include'
    })
}

export function useKpisPanelData(): KpiMetadata[] {
  const kpis = useFetch<KpiMetadata[]>(`${baseApiUrl}/panel/kpi`, {
    credentials: 'include'
  })

  return kpis
}

export function useKpisPanel(): {
  refreshKpis: () => Promise<KpiMetadata[]>
  updateKpi: (kpi: KpiMetadata) => Promise<KpiMetadata>
  createKpi: (kpi: KpiMetadata) => Promise<KpiMetadata>
  removeKpi: (id: string) => Promise<void>
} {
  const refresh = useFetchCb<KpiMetadata[]>()
  const fetch = useFetchUncachedCb()

  return {
    refreshKpis: () =>
      refresh(`${baseApiUrl}/panel/kpi`, {
        credentials: 'include'
      }),
    updateKpi: (kpi: KpiMetadata) =>
      fetch(`${baseApiUrl}/panel/kpi/${kpi.id}`, {
        method: 'PUT',
        body: kpi,
        credentials: 'include'
      }),
    createKpi: (kpi: KpiMetadata) =>
      fetch(`${baseApiUrl}/panel/kpi`, {
        method: 'POST',
        body: kpi,
        credentials: 'include'
      }),
    removeKpi: (id: string) =>
      fetch(`${baseApiUrl}/panel/kpi/${id}`, {
        method: 'DELETE',
        credentials: 'include'
      })
  }
}

export function useRoles(): Roles[] {
  const roles = useFetch<Roles[]>(`${baseApiUrl}/panel/roles`, {
    credentials: 'include'
  })
  return roles
}

export function useUserRoles(): UserAccountDto[] {
  const userRoles = useFetch<UserAccountDto[]>(`${baseApiUrl}/panel/users`, {
    credentials: 'include'
  })
  return userRoles
}

export function useUserRolesRefresh(): () => Promise<UserAccountDto[]> {
  const refresh = useFetchCb<UserAccountDto[]>()
  return () =>
    refresh(`${baseApiUrl}/panel/users`, {
      credentials: 'include'
    })
}

export function useUpdateUserRoles(): (data: UserAccountDto) => Promise<void> {
  const fetch = useFetchUncachedCb()
  return (data: UserAccountDto) =>
    fetch(`${baseApiUrl}/panel/user/${data.id}`, {
      method: 'PUT',
      credentials: 'include',
      body: data
    })
}

export function useRoleMetadata(): RoleMetadataDto[] {
  const data = useFetch<RoleMetadataDto[]>(`${baseApiUrl}/panel/permissions`, {
    credentials: 'include'
  })
  return data
}

export function usePanelRoleMetadata() {
  const fetch = useFetchUncachedCb()
  const refresh = useFetchCb<RoleMetadataDto[]>()

  const update = (data: UpdateRoleMetadata) =>
    fetch(`${baseApiUrl}/panel/permissions`, {
      body: data,
      credentials: 'include',
      method: 'POST'
    })

  const refreshData = () =>
    refresh(`${baseApiUrl}/panel/permissions`, {
      credentials: 'include'
    })

  return {
    update,
    refreshData
  }
}
