import Check from '../Pieces/Check'

interface NaturalRadioProps {
  checked: boolean
  handleChange: () => void
}

export default function NaturalRadio({
  checked,
  handleChange
}: NaturalRadioProps) {
  return (
    <Check
      checked={checked}
      handleChange={() => handleChange()}
      label="edit.table.head.nature"
    />
  )
}
