import { FormattedMessage } from '@components/Intl/FormattedMessage'

interface TitleInputProps {
  value: string
  handleChange: (value: string) => void
}

export default function TitleInput({ value, handleChange }: TitleInputProps) {
  return (
    <>
      <label htmlFor="title">
        <FormattedMessage id="edit.table.head.title" />
      </label>
      <input
        name="title"
        required
        placeholder="Titulo"
        type="text"
        value={value}
        onChange={({ target: { value } }) => handleChange(value)}
      />
    </>
  )
}
