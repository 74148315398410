import {
  FormattedMessage,
  IntlMessageKeys
} from '@components/Intl/FormattedMessage'

interface CheckProps {
  label: IntlMessageKeys
  checked: boolean
  handleChange: () => void
}

export default function Check({ checked, handleChange, label }: CheckProps) {
  return (
    <>
      <label htmlFor="natural">
        <FormattedMessage id={label} />
      </label>
      <input
        name="natural"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
    </>
  )
}
