import { FormattedMessage } from '@components/Intl/FormattedMessage'

interface TargetInputProps {
  value: string | number
  handleChange: (value: string) => void
}

export default function TargetInput({ handleChange, value }: TargetInputProps) {
  return (
    <>
      <label htmlFor="target">
        <FormattedMessage id="edit.table.head.target" />
      </label>
      <input
        required
        step="any"
        name="target"
        type="number"
        placeholder="Objetivo"
        value={value ?? ''}
        onChange={({ target: { value } }) => handleChange(value)}
      />
    </>
  )
}
