import CustomSelect from '@components/CustomSelect/CustomSelect'
import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'

export interface SelectOption {
  label: string
  value: string
}

function OptionComponent({
  option,
  selected
}: {
  option: SelectOption
  selected: boolean
}) {
  return <span className={selected ? 'selected' : ''}>{option.label}</span>
}

function CategoryLabelComponent({ label }: { label: string }) {
  return (
    <div>
      {label ? (
        <span>
          <FormattedMessage id={`overview.filter.${label}.name`} />
        </span>
      ) : (
        <span>
          <FormattedMessage id="edit.category.empty" />
        </span>
      )}
      <span className="icon-arrow" />
    </div>
  )
}

function SubcategoryLabelComponent({ label }: { label: string }) {
  return (
    <div>
      <span>
        {label ? (
          <FormattedMessage
            id={`overview.filter.production.subcategories.${label}`}
          />
        ) : null}
      </span>
      <span className="icon-arrow" />
    </div>
  )
}

function UnitLabelComponent({ label }: { label: string }) {
  return (
    <div>
      {label ? (
        <span>
          <FormattedMessage id={`edit.units.${label}`} />
        </span>
      ) : (
        <span>
          <FormattedMessage id="edit.units.empty" />
        </span>
      )}
      <span className="icon-arrow" />
    </div>
  )
}

interface SelectProps {
  options: SelectOption[]
  value: string
  handleChange: (value: string) => void
  name: string
  component: 'unit' | 'subcategory' | 'category'
}

export default function Select({
  options,
  value,
  name,
  component,
  handleChange
}: SelectProps) {
  const recordComponents: Record<
    'unit' | 'subcategory' | 'category',
    ReactNode
  > = {
    category: <CategoryLabelComponent label={value} />,
    subcategory: <SubcategoryLabelComponent label={value} />,
    unit: <UnitLabelComponent label={value} />
  }

  return (
    <>
      <CustomSelect
        OptionComponent={OptionComponent}
        LabelComponent={recordComponents[component]}
        options={options}
        value={value}
        onChange={{
          add: (value) => handleChange(value)
        }}
      />
      <input
        className="hidden"
        name={name}
        type="text"
        defaultValue={value}
        required
      />
    </>
  )
}
