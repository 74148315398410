import { FormattedMessage } from 'react-intl'
import Select, { SelectOption } from '../Pieces/Select'

interface CategorySelectProps {
  categoriesSelect: SelectOption[]
  value: string
  handleChange: (value: string) => void
}

export default function CategorySelect({
  categoriesSelect,
  value,
  handleChange
}: CategorySelectProps) {
  return (
    <>
      <label htmlFor="category">
        <FormattedMessage id="edit.table.head.category" />
      </label>
      <Select
        component="category"
        handleChange={(value) => handleChange(value)}
        name="category"
        options={categoriesSelect}
        value={value}
      />
    </>
  )
}
