import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { UserData } from 'src/server/types'

const initialState: UserData | null = null as UserData

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(
      _state,
      action: PayloadAction<{ token: string; role: string; id: string }>
    ) {
      return {
        id: action.payload.id,
        token: action.payload.token,
        isAdmin: action.payload.role === 'ADMIN'
      }
    },
    load(_state, action: PayloadAction<UserData>) {
      return action.payload
    },
    logout() {
      return null
    }
  }
})

export const { load, login, logout } = userSlice.actions
export default userSlice.reducer
