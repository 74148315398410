import Formula from '../Pieces/Formula'

import { LayoutProps } from './TargetFormulaLayout'
import { Units } from 'src/server/types'
import TitleInput from '../components/TitleInput'
import TargetInput from '../components/TargetInput'
import CategorySelect from '../components/CategorySelect'
import NaturalRadio from '../components/NaturalRadio'
import SubcategorySelect from '../components/SubcategorySelect'
import WarehouseRadio from '../components/WarehouseRadio'
import FrequencyRadio from '../components/FrequencyRadio'
import UnitSelect from '../components/UnitSelect'
import NegativeRadio from '../components/NegativeRadio'
import { FormattedMessage } from '@components/Intl/FormattedMessage'

export default function ValueFormulaLayout({
  categoriesSelect,
  editedKpi,
  setEditedKpi,
  subcategoriesSelect,
  units
}: LayoutProps) {
  return (
    <section>
      <div>
        <TitleInput
          handleChange={(value) =>
            setEditedKpi((prev) => ({ ...prev, title: value }))
          }
          value={editedKpi.title ?? ''}
        />
      </div>
      <div>
        <WarehouseRadio
          setEditedKpi={setEditedKpi}
          selectedValue={editedKpi.warehouse?.toLowerCase()}
        />
      </div>
      <div>
        <Formula
          name="formula"
          title="edit.dialog.formula"
          formula={editedKpi.formula}
          handleChange={(value) =>
            setEditedKpi((prev) => ({ ...prev, formula: value }))
          }
        />
      </div>
      <div>
        <FrequencyRadio
          setEditedKpi={setEditedKpi}
          selectedValue={editedKpi.kpiFrequency}
        />
      </div>
      <div>
        <TargetInput
          value={editedKpi.target ?? ''}
          handleChange={(value) =>
            setEditedKpi((prev) => ({
              ...prev,
              target: Number(value)
            }))
          }
        />
      </div>
      <div className="checkbox-container">
        <label>
          <FormattedMessage id="edit.dialog.params" />
        </label>
        <div>
          <div>
            <NaturalRadio
              checked={editedKpi.isNatural ?? false}
              handleChange={() =>
                setEditedKpi((prev) => ({
                  ...prev,
                  isNatural: !prev.isNatural
                }))
              }
            />
          </div>
          <div>
            <NegativeRadio
              checked={editedKpi.isNegative ?? false}
              handleChange={() =>
                setEditedKpi((prev) => ({
                  ...prev,
                  isNegative: !prev.isNegative
                }))
              }
            />
          </div>
        </div>
      </div>
      <div>
        <CategorySelect
          categoriesSelect={categoriesSelect}
          handleChange={(value) =>
            setEditedKpi((prev) => ({ ...prev, category: value }))
          }
          value={editedKpi.category}
        />
      </div>
      <div></div>
      <div>
        <SubcategorySelect
          category={editedKpi.category}
          handleChange={(value) =>
            setEditedKpi((prev) => ({
              ...prev,
              subcategory: value
            }))
          }
          subcategoriesSelect={subcategoriesSelect}
          value={editedKpi.subcategory}
        />
      </div>
      <div></div>
      <div>
        <UnitSelect
          handleChange={(value) =>
            setEditedKpi((prev) => ({ ...prev, unit: value as Units }))
          }
          units={units}
          value={editedKpi.unit}
        />
      </div>
    </section>
  )
}
